export const USERS = 'users';
export const PATIENTS = 'patients';
export const TREATMENT_PLANS = 'treatment_plans';
export const SKIPPED = 'skipped';
export const EXERCISES = 'exercises';
export const COMPLETION = 'completion';
export const SESSIONS = 'sessions';
export const TREATMENT_PATIENT_FEEDBACK = 'treatment_patient_feedback';
export const EVENT_STREAM = 'event_stream';
export const STAGES = 'stages';
export const PROGRAMME = 'programme';
export const CLIENTS = 'clients';
export const MESSAGE_THREADS = 'message_threads';
export const MESSAGES = 'messages';
export const TRIAGE = 'triage';
export const TRIAGE_BODY_CHARTS = 'triage_body_charts';
export const ENGAGE_ADHERENCE = 'engage_adherence';
export const DIFFICULTY_TRACKER = 'difficulty_tracker';
export const ACTIVITY_TRACKER = 'activity_tracker';
export const INJURY_MANAGEMENT = 'injury_management';
export const INJURY_MANAGEMENT_PROGRAMMES = 'injury_management_programmes';
export const INJURY_MANAGEMENT_STAGES = 'injury_management_stages';
export const INJURY_MANAGEMENT_SETS = 'injury_management_sets';
export const INJURY_MANAGEMENT_EXERCISES = 'injury_management_exercises';
export const INJURY_MANAGEMENT_VIDEOS = 'injury_management_videos';
export const TREATMENT_LOGS = 'treatment_logs';
export const DAILY_ASSESSMENT = 'daily_assessment';
export const WEEKLY_ASSESSMENT = 'weekly_assessment';
export const KIT_REQUESTS = 'kit_requests';
export const CLIENT_CLINICAL_LOGS = 'client_clinical_logs';
export const OTP_CODES = 'otp_codes';
export const ONE_TIME_PASSCODE = 'one_time_passcode';
export const STATUS = 'status';
export const SUPPORT_CASES = 'support_cases';
export const WEEKS = 'weeks';
export const SESSION_TRACKER = 'session_tracker';
export const INJURY_REHAB_CASES = 'injury_rehab_cases';
export const REHAB_RECORDS = 'rehab_records';
export const REHAB_TRIAGE_RECORDS = 'rehab_triage_records';
export const TRIAGE_REGISTRATION_CONFIGS = 'triage_registration_configs';
export const MEMBERSHIP = 'membership';
export const MEDICAL_DEVICE = 'medical_device_settings';
