import { Box } from '@mui/material';
import TriageTable from './TriageTable';
import { useEffect, useState } from 'react';
import { TriageRegistrationConfig } from '@eql-ai/typescript-types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { getRegistrationConfigByClientId } from 'data/registrationConfig';

const TriageContainer = () => {
    const [registrationConfig, setRegistrationConfig] = useState<
        TriageRegistrationConfig | null | undefined
    >();
    const [loading, setLoading] = useState(false);

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    const fetchRegConfig = async () => {
        try {
            setLoading(true);

            const data = await getRegistrationConfigByClientId(client.id);

            setRegistrationConfig(data as unknown as TriageRegistrationConfig);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchRegConfig();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client.id]);

    return (
        <Box>
            <TriageTable
                registrationConfig={registrationConfig}
                regConfigLoading={loading}
                client={client}
            />
        </Box>
    );
};

export default TriageContainer;
