import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RootState } from 'redux/reducers';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

function AuthGuard({ children }: { children: ReactJSXElement }) {
    const location = useLocation();
    const account = useSelector((state: RootState) => state.AuthReducer.user.email);

    if (!account) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
