import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Backdrop, Box, CircularProgress, Container, Paper, Typography } from '@mui/material';
import { firestoreInstance } from 'config/ApiService';
import { PATIENTS } from 'core/utils/collectionNames';
import EditPatientForm from './PatientForm';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { TriageRegistrationConfig } from '@eql-ai/typescript-types';
import { getRegistrationConfigByClientId } from 'data/registrationConfig';
import { ExtendedPatient } from 'services/patient';

const EditPatientContainer = () => {
    const [patient, setPatient] = React.useState<ExtendedPatient | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingPatients, setLoadingPatients] = React.useState(false);
    const [registrationConfig, setRegistrationConfig] = React.useState<
        TriageRegistrationConfig | null | undefined
    >();

    const params = useParams();
    const navigate = useNavigate();

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    const fetchRegConfig = async () => {
        try {
            setLoading(true);

            const data = await getRegistrationConfigByClientId(client.id);

            setRegistrationConfig(data as unknown as TriageRegistrationConfig);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchPatientData = async () => {
        try {
            setLoadingPatients(true);

            const { id } = params;

            if (!id) {
                return;
            }
            await getDoc(doc(firestoreInstance(), PATIENTS, id)).then((doc) => {
                setPatient({ ...doc.data(), id: doc.id } as ExtendedPatient);
            });
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingPatients(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            await fetchPatientData();
            await fetchRegConfig();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <>
            <Container>
                <Box>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            fontWeight: 'bold',
                            marginTop: '16px'
                        }}
                    >
                        Edit Patient UID: {patient?.id}
                    </Typography>
                </Box>

                <Paper sx={{ minHeight: '400px' }}>
                    {patient && (
                        <EditPatientForm
                            onDismiss={() => navigate(-1)}
                            patient={patient}
                            registrationConfig={registrationConfig}
                        />
                    )}
                </Paper>
            </Container>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading || loadingPatients}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default EditPatientContainer;
