import React, { useContext } from 'react';
import { ConfirmContext } from '../contexts/ConfirmContextProvider';

const useConfirm = () => {
    const [confirm, setConfirm] = useContext(ConfirmContext);
    const [needsCleanup, setNeedsCleanup] = React.useState(false);

    const isConfirmed = (prompt: string, title?: string) => {
        setNeedsCleanup(true);
        const promise = new Promise((resolve, reject) => {
            setConfirm({
                prompt,
                isOpen: true,
                title: title || '',
                proceed: resolve,
                cancel: reject
            });
        });
        return promise.then(
            () => {
                setConfirm({ ...confirm, isOpen: false });
                return true;
            },
            () => {
                setConfirm({ ...confirm, isOpen: false });
                return false;
            }
        );
    };

    React.useEffect(() => {
        return () => {
            if (confirm.cancel && needsCleanup) {
                confirm.cancel();
            }
        };
    }, [confirm, needsCleanup]);

    return {
        ...confirm,
        isConfirmed
    };
};

export default useConfirm;
