import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Container,
    TextField,
    Avatar,
    Grid,
    Link,
    Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../config/ApiService';
import validateEmail from 'utils/emailValidation';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    }
}));

const ForgotPasswordContainer = () => {
    const [email, setEmail] = React.useState('');
    const classes = useStyles();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const handleTriggerPasswordReset = async () => {
        if (!validateEmail(email)) {
            enqueueSnackbar('The email format is invalid', { variant: 'error' });
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
        } finally {
            enqueueSnackbar(
                `Please check your email for instructions on how to reset your password`,
                {
                    variant: 'success'
                }
            );
        }
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            navigate('/signin');
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Sign in
                    </Typography>
                    <Button color="inherit">EQL</Button>
                </Toolbar>
            </AppBar>
            <main>
                <Container component="main" maxWidth="sm">
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={(evt) => setEmail(evt.target.value)}
                                        error={!email.match(/@/)}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleTriggerPasswordReset}
                                disabled={!email.match(/@/)}
                            >
                                Reset
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link href="/signin" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </main>
        </div>
    );
};

export default ForgotPasswordContainer;
