import React from 'react';
import { useParams } from 'react-router-dom';
import { Backdrop, Box, CircularProgress, Container, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { TriageRegistrationConfig } from '@eql-ai/typescript-types';
import { getRegistrationConfigByClientId } from 'data/registrationConfig';
import BulkPatientsUpload from './BulkPatientsUpload';

const UploadPatients = () => {
    const [loading, setLoading] = React.useState(false);
    const [registrationConfig, setRegistrationConfig] = React.useState<
        TriageRegistrationConfig | null | undefined
    >();

    const params = useParams();

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    const fetchRegConfig = async () => {
        try {
            setLoading(true);

            const data = await getRegistrationConfigByClientId(client.id);

            setRegistrationConfig(data as unknown as TriageRegistrationConfig);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            await fetchRegConfig();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <>
            <Container>
                <Box>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            fontWeight: 'bold',
                            marginTop: '16px'
                        }}
                    >
                        Import patients for {client.name}
                    </Typography>
                </Box>

                <Paper>
                    <BulkPatientsUpload registrationConfig={registrationConfig} />
                </Paper>
            </Container>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default UploadPatients;
