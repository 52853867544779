import CustomerModelMapper from 'core/mappers/CustomerModelMapper';
import CustomerModel from 'core/models/CustomerModel';
import { doc, getDoc } from 'firebase/firestore';
import { firestoreInstance } from 'config/ApiService';
import { CLIENTS } from 'core/utils/collectionNames';

class CustomerDAO {
    mapper = new CustomerModelMapper();

    async getCustomerInfo(customerId: string): Promise<CustomerModel | undefined> {
        const ref = doc(firestoreInstance(), CLIENTS, customerId);
        const res = await getDoc(ref);
        return this.mapper.fromSnapshot(res);
    }
}

export default CustomerDAO;
