import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    ListItemIcon,
    SelectChangeEvent,
    FormHelperText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

interface MultipleFilterType {
    options: string[];
    label: string;
    fullWidth: boolean;
    showHelperOnlyOnError?: boolean;
    onSelected: any;
    selectedValue: string[];
}

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 120
    }
}));

const HelperText = ({ selected }: { selected: string[] }) => (
    <FormHelperText>
        This filter has a limit of 10 options. You currently have {selected?.length} selected.
    </FormHelperText>
);

const MultipleFilter = (props: MultipleFilterType) => {
    const { options, label, fullWidth, showHelperOnlyOnError, onSelected, selectedValue } = props;

    const [selected, setSelected] = useState<string[]>(selectedValue || ['All']);

    const isAllSelected = selected.includes('All');

    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const value = event.target.value;
        if (value[value.length - 1] === 'All') {
            setSelected(['All']);
            return;
        }

        setSelected([...value]);
    };

    const handleClose = () => {
        if (onSelected) {
            onSelected(selected);
        }
    };

    const classes = useStyles();

    const limitError = selected?.length > 10;

    return (
        <FormControl
            fullWidth={fullWidth}
            variant="outlined"
            className={classes.formControl}
            error={limitError}
        >
            <InputLabel id="multiple-select-label">{label}</InputLabel>
            <Select
                labelId="multiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                onClose={handleClose}
                renderValue={(selected) => selected?.join(', ')}
                label={label}
            >
                <MenuItem value="All">
                    <ListItemIcon>
                        <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary="All" />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option} disabled={isAllSelected}>
                        <ListItemIcon>
                            <Checkbox checked={selected?.indexOf(option) > -1 && !isAllSelected} />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
            {showHelperOnlyOnError ? (
                limitError && <HelperText selected={selected} />
            ) : (
                <HelperText selected={selected} />
            )}
        </FormControl>
    );
};

export default MultipleFilter;
