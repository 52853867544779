const AuthActions = {
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    SET_REGIONS: 'SET_REGIONS',
    SET_HAS_WEAK_PASSWORD: 'SET_HAS_WEAK_PASSWORD',
    SET_USER: 'SET_USER',
    SET_LOCATION: 'SET_LOCATION',
    LOG_FOR_AUDIT: 'LOG_FOR_AUDIT',
    SET_ORGANISATION: 'SET_ORGANISATION'
};

export default AuthActions;
