import { firestoreInstance } from 'config/ApiService';
import { TRIAGE_REGISTRATION_CONFIGS } from 'core/utils/collectionNames';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const getRegistrationConfigByClientId = async (clientId: string) => {
    try {
        const ref = query(
            collection(firestoreInstance(), TRIAGE_REGISTRATION_CONFIGS),
            where('client_id', '==', clientId)
        );
        const res = await getDocs(ref);
        if (res.empty) {
            return null;
        }
        return { ...res.docs[0].data(), id: res.docs[0].id };
    } catch (e) {
        console.log(e);
        return null;
    }
};
