import React from 'react';
import Chart from 'react-apexcharts';
import { Box } from '@mui/material';
import { ApexOptions } from 'apexcharts';

interface Props {
    series: Array<number>;
    labels: Array<string>;
}

const GenericPieChart = (props: Props) => {
    const { series, labels } = props;
    const options: ApexOptions = {
        chart: {
            width: 380,
            type: 'pie'
        },
        labels: labels,
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        ]
    };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
            alignItems="center"
        >
            <Chart options={options} series={series} type="pie" width={380} />
        </Box>
    );
};

export default GenericPieChart;
