import { ReactElement } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

interface Props {
    icon?: ReactElement;
    primary: string;
    to: string;
}

function ListItemLink(props: Props) {
    const { icon, primary, to } = props;
    const location = useLocation();

    return (
        <>
            <ListItemButton
                selected={location.pathname.match(to) !== null}
                component={Link}
                to={to}
            >
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItemButton>
        </>
    );
}

export default ListItemLink;
