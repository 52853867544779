import { Box } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { makeStyles } from '@mui/styles';

const ISO_NORMALIZED: { [propName: string]: string } = {
    gb: 'gb',
    aus: 'au'
};

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: 1,
        marginRight: 1,
        display: 'flex'
    },
    flag: {
        fontSize: '2em !important',
        lineHeight: '2em !important'
    }
}));

const RegionFlagComponent = ({ region }: { region: string }) => {
    const classes = useStyles();
    return (
        <Box sx={{ marginLeft: 1, marginRight: 1, display: 'flex' }}>
            <ReactCountryFlag className={classes.flag} countryCode={ISO_NORMALIZED[region]} />
        </Box>
    );
};

export default RegionFlagComponent;
