import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

const CurrentUserNavBarItem = () => {
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    return (
        <Chip
            label={clinician ? clinician.email : ''}
            variant="outlined"
            color="secondary"
            style={{ color: '#fff' }}
        />
    );
};

export default CurrentUserNavBarItem;
