import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { addHours, subHours } from 'date-fns';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreInstance } from '../../config/ApiService';
import { EXERCISES, SKIPPED, TREATMENT_PATIENT_FEEDBACK } from '../../core/utils/collectionNames';

const SkippedToday = () => {
    const [count, setCount] = React.useState<number>(0);
    const refDate = new Date();
    const baseDate = new Date(
        refDate.getFullYear(),
        refDate.getMonth(),
        refDate.getDate(),
        0,
        0,
        0
    );
    const fromDate = subHours(baseDate, baseDate.getHours());
    const toDate = addHours(fromDate, 24);
    React.useEffect(() => {
        const ref = collection(firestoreInstance(), TREATMENT_PATIENT_FEEDBACK, SKIPPED, EXERCISES);

        const q = query(
            ref,
            where('submitted_at', '>=', fromDate),
            where('submitted_at', '<=', toDate)
        );

        getDocs(q).then((snapshots) => {
            // console.log(snapshots.docs.length);
            setCount(snapshots.docs.length);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                NUMBER OF SKIPPED EXERCISES TODAY
            </Typography>
            <Typography component="p" variant="h4">
                {count}
            </Typography>
            <Divider />
            <Box></Box>
        </div>
    );
};

export default SkippedToday;
