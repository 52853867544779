const AppActions = {
    SET_CLIENT: 'SET_CLIENT',
    SET_PATIENT_DATA: 'SET_PATIENTS',
    SET_ENV: 'SET_ENV',
    SET_TRIAGE_DATA: 'SET_TRIAGE_DATA',
    SET_WEEKLY_ASSESSMENT: 'SET_WEEKLY_ASSESSMENT',
    SET_DAILY_ASSESSMENT: 'SET_DAILY_ASSESSMENT',
    SET_SKIPPED_DATA: 'SET_SKIPPED_DATA',
    SET_SESSION_DATA: 'SET_SESSION_DATA',
    SET_TRIAGE_FILTER_STATUS: 'SET_TRIAGE_FILTER_STATUS',
    SET_TRIAGE_DATE_FILTER_FROM: 'SET_TRIAGE_DATE_FILTER_FROM',
    SET_TRIAGE_DATE_FILTER_TO: 'SET_TRIAGE_DATE_FILTER_TO',
    SET_PATIENT_FILTER: 'SET_PATIENT_FILTER',
    SET_STATUS_FILTER: 'SET_STATUS_FILTER',
    ASSIGN_CLINICIAN_TO_PATIENT: 'ASSIGN_CLINICIAN_TO_PATIENT',
    SET_PROGRAMME_IN_EDIT: 'SET_PROGRAMME_IN_EDIT',
    CLEAR_PROGRAMME_IN_EDIT: 'CLEAR_PROGRAMME_IN_EDIT'
};

export default AppActions;
