import { useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RootState } from 'redux/reducers';
import { IdTokenResult } from 'firebase/auth';

const packages = [
    'engage_management',
    'access_management',
    'workflow',
    'internal_clinical_services',
    'user_management',
    'super_user_management',
    'triage_review_crm',
    'product_configuration',
    'customer_support',
    'management_information',
    'customer_support'
] as const;

export type Package = (typeof packages)[number];

export const retrievePermissions = (token: IdTokenResult) => {
    const permissions: string[] = [];
    Object.keys(token.claims).forEach((cl) => {
        if (packages.includes(cl as Package) && token.claims[cl] === true) {
            permissions.push(cl);
        }
    });
    // sort in order of the packages
    return permissions.sort(
        (a, b) => packages.indexOf(a as Package) - packages.indexOf(b as Package)
    );
};

function AclGuard({ acl }: { acl: Package[] }) {
    const location = useLocation();
    const permissions = useSelector((state: RootState) => state.AuthReducer.permissions);
    //Find the permission list in the acl list
    const isPermitted = acl.some((permission) => permissions.includes(permission));
    if (!isPermitted) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }
    return <Outlet />;
}

AclGuard.propTypes = {
    children: PropTypes.any,
    acl: PropTypes.arrayOf(PropTypes.string)
};

export default AclGuard;
