import { httpsCallable, HttpsCallableResult } from '@firebase/functions';
import { functions } from 'config/ApiService';

type PdfPayload = {
    triageId: string;
    region: string;
};

type EngagePdfPayload = {
    patientId: string;
    region: string;
};

type PdfResponse = {
    pdfAsBase64String: string;
    status: number;
};

/**
 * @remarks - This function calls the cloud function to generate a pdf report for a triage
 * @param {object} payload - payload to be sent to the cloud function.
 * `triageId` is the id of the triage to be used to generate the report
 * @returns `httpsCallableResult<PdfResponse>` - the response from the cloud function
 */
export function triagePdfReport(payload: PdfPayload): Promise<HttpsCallableResult<PdfResponse>> {
    return httpsCallable<PdfPayload, PdfResponse>(functions, 'pdf-generateTriageReport')(payload);
}

export function engageDischargePdfReport(
    payload: EngagePdfPayload
): Promise<HttpsCallableResult<PdfResponse>> {
    return httpsCallable<EngagePdfPayload, PdfResponse>(
        functions,
        'pdf-generateEngageDischargeReport'
    )(payload);
}

/**
 * @remarks - This function inserts an anchor tag into the DOM and clicks it to force the browser to download the file
 * @param {string} base64String - base64 encoded string of the pdf file
 * @param {string} fileName - name of the file to be downloaded
 * @returns forces download of the file in the browser to disk
 */
export const downloadPdfReport = async (base64String: string, fileName?: string) => {
    const base64 = await fetch(base64String);
    const blob = await base64.blob();

    const link = document.createElement('a');
    document.body.appendChild(link);

    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName || 'triage-report.pdf';
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
};

export const openInNewWindowPdfReport = async (base64String: string) => {
    const base64 = await fetch(base64String);
    const blob = await base64.blob();
    const link = document.createElement('a');
    document.body.appendChild(link);
    const url = window.URL.createObjectURL(blob);

    window.open(url, '_blank', 'noopener,noreferrer');

    document.body.removeChild(link);
};
