import React, { Suspense } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Box, CssBaseline, Theme } from '@mui/material';
import CopyRight from 'components/CopyRight';
import TopBar from 'components/TopBar';
import SideDrawer from 'components/SideDrawer';
import AuthActionCreator from 'redux/creators/AuthActionCreators';
import AuthActions from 'redux/actions/AuthActions';
import { LOG_TYPES } from 'utils/Logger';
import { Outlet } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestoreInstance } from '../config/ApiService';
import { signOut } from 'firebase/auth';
import { CLIENTS, USERS } from '../core/utils/collectionNames';
import { RootState } from 'redux/reducers';
import { User } from '@eql-ai/typescript-types';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        background: '#f4f6f8'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

const DashboardLayout = () => {
    const [openDrawer, setOpenDrawer] = React.useState(true);
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    const databaseId = useSelector((state: RootState) => state.AuthReducer.databaseId);
    const dispatch = useDispatch();

    const classes = useStyles();

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };
    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleLogout = async () => {
        dispatch(
            AuthActionCreator.logForAudit({
                target: {
                    id: clinician.id,
                    description: 'Logged out by system',
                    affectedUser: clinician.email
                },
                type: LOG_TYPES.READ
            })
        );
        signOut(auth);
        dispatch({ type: AuthActions.SIGN_OUT });
    };

    React.useEffect(() => {
        try {
            (async () => {
                const ref = doc(firestoreInstance(databaseId), USERS, clinician.id);
                const orgRef = doc(firestoreInstance(databaseId), CLIENTS, clinician.client_id);
                const orgQuery = await getDoc(orgRef);
                if (!orgQuery.exists()) {
                    handleLogout();
                    return;
                }
                const orgData = orgQuery.data();
                if (!orgData.validated) {
                    handleLogout();
                    return;
                }
                const query = await getDoc(ref);
                if (!query.exists()) {
                    handleLogout();
                    return;
                }
                const user = { ...query.data(), id: query.id } as User;
                if (user.status !== 'active') {
                    handleLogout();
                    return;
                }
                dispatch(AuthActionCreator.setUser(user));
            })();
        } catch (e) {
            console.error(e);
        }
        // eslint-disable-next-line
    }, [databaseId]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <TopBar onDrawerOpen={handleDrawerOpen} open={openDrawer} />
            <SideDrawer onDrawerClose={handleDrawerClose} open={openDrawer} />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Outlet />
                    </Suspense>
                    <Box pt={4}>
                        <CopyRight />
                    </Box>
                </Container>
            </main>
        </div>
    );
};

export default DashboardLayout;
