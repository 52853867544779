const POSTCODE_REGEX_GB =
    /(^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -] ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$)/i;
const POSTCODE_REGEX_IE = /(^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?([0-9AC-FHKNPRTV-Y]{4}$)/i;

const POSTCODE_REGEX_AUS = /^(0[289][0-9]{2})$|^([1-9][0-9]{3})$/;

const postcodeValidator = {
    validate: (input: any, region: 'gb' | 'aus') => {
        if (region === 'aus') {
            return POSTCODE_REGEX_AUS.test(input);
        }
        if (region === 'gb') {
            return POSTCODE_REGEX_GB.test(input) || POSTCODE_REGEX_IE.test(input);
        }
        return false;
    }
};

export default postcodeValidator;
