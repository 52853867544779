import { Typography, Link } from '@mui/material';

const CopyRight = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://eql.ai">
                EQL
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'} <br />
            {`v${process.env.REACT_APP_VERSION}`}
        </Typography>
    );
};

export default CopyRight;
