export const LOG_TYPES = {
    CREATE: 'create',
    DELETE: 'delete',
    UPDATE: 'update',
    READ: 'read',
    DOWNLOADED_PDF: 'downloaded_pdf',
    WORKFLOW: 'workflow'
};

// export class Log {
//     #executor;
//     #target;
//     #type;
//     constructor(executor, target, type) {
//         this.#executor = executor;
//         this.#target = target;
//         this.#type = type;
//     }

//     get log() {
//         return {
//             executor: this.#executor,
//             target: this.#target,
//             type: this.#type
//         };
//     }
// }

// const Logger = (client) => {
//     const ref = firebase.firestore().collection('client_clinical_logs');
//     return async (log) => {
//         await ref.add({
//             ...log,
//             time: firebase.firestore.FieldValue.serverTimestamp(),
//             client_id: client.id,
//             executor_id: log.executor.id,
//             executor_name: `${log.executor.first_name} ${log.executor.last_name}`
//         });
//     };
// };
// export default Logger;
