import { Tooltip } from '@mui/material';

interface IconTooltipProps {
    children: React.ReactElement;
    title?: string;
}

const IconTooltip = (props: IconTooltipProps) => {
    const { title, children } = props;
    return (
        <Tooltip title={title}>
            <span>{children}</span>
        </Tooltip>
    );
};

export default IconTooltip;
