import { createStyles, makeStyles } from '@mui/styles';
import type { Theme } from '@mui/material/styles';
import { Typography, Divider, Box } from '@mui/material';
import Title from './Title';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        depositContext: {
            flex: 1
        },
        item: {
            textAlign: 'center',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // padding: theme.spacing(3, 2),
            '&:not(:last-of-type)': {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        }
    })
);

interface Props {
    total?: number;
    closed?: number;
    completed?: number;
    waiting?: number;
    onboarded?: number;
}

const CaseCountTile = (props: Props) => {
    const { total, closed, completed, waiting, onboarded } = props;
    const classes = useStyles();
    return (
        <>
            <Title>NUMBER OF CASES ON ENGAGE</Title>
            <Typography component="p" variant="h4">
                {total}
            </Typography>
            <Divider />
            <Box display="flex" flex={1}>
                <Box flex={1} display="flex" flexDirection="column" className={classes.item}>
                    <Title>Closed</Title>
                    <Typography variant="body2">{closed}</Typography>
                </Box>
                <Box flex={1} display="flex" flexDirection="column" className={classes.item}>
                    <Title>Completed</Title>
                    <Typography variant="body2">{completed}</Typography>
                </Box>
                <Box flex={1} display="flex" flexDirection="column" className={classes.item}>
                    <Title>Onboarded</Title>
                    <Typography variant="body2">{onboarded}</Typography>
                </Box>
                <Box flex={1} display="flex" flexDirection="column" className={classes.item}>
                    <Title>Waiting</Title>
                    <Typography variant="body2">{waiting}</Typography>
                </Box>
            </Box>
        </>
    );
};

export default CaseCountTile;
