/*
    NHS rules based on:
    - https://digital.nhs.uk/services/nhs-number/nhs-number-client
    - https://en.wikipedia.org/wiki/NHS_number
    - https://www.closer.ac.uk/wp-content/uploads/CLOSER-NHS-ID-Resource-Report-Apr2018.pdf
*/

const isAllNumbers = /\d{10}/;

const validateNhsNumber = (nhsNumber: string) => {
    const nhsNumberNoSpaces = nhsNumber.replace(/\s/g, '');

    if (!isAllNumbers.test(nhsNumberNoSpaces)) {
        return false;
    }

    // nhs number must be 10 characters
    if (nhsNumberNoSpaces.length !== 10) {
        return false;
    }

    const digits = [...nhsNumberNoSpaces].map((value) => Number.parseInt(value, 10));
    const checksum = digits[9];

    let checksumCalcA = 0;
    digits.slice(0, 9).forEach((digit, index) => {
        const multiplier = 10 - index;
        checksumCalcA += digit * multiplier;
    });

    const checksumCalcB = 11 - (checksumCalcA % 11);
    const checksumCalcC = checksumCalcB === 11 ? 0 : checksumCalcB;

    if (checksumCalcC === 10) {
        return false;
    }

    if (checksum !== checksumCalcC) {
        return false;
    }

    return true;
};

export default validateNhsNumber;
