import React, { createContext, ReactNode, useState } from 'react';

export const ConfirmContext = createContext<any>({});

interface Props {
    children: ReactNode
}

const ConfirmContextProvider: React.FC<Props> = ({ children }) => {
    const [confirm, setConfirm] = useState({
        prompt: '',
        title: '',
        isOpen: false,
        proceed: null,
        cancel: null
    });

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmContext.Provider>
    );
};
export default ConfirmContextProvider;
