import { isDevMode } from '../utils/AppEnv';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {  getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

const stageConfig = {
    apiKey: 'AIzaSyA9pOswwGAtpQEsUezRGBI92NctDuRQzuA',
    authDomain: 'phio-dev-c5d2d.firebaseapp.com',
    databaseURL: 'https://phio-dev-c5d2d.firebaseio.com',
    projectId: 'phio-dev-c5d2d',
    storageBucket: 'phio-dev-c5d2d.appspot.com',
    messagingSenderId: '831737646743',
    appId: '1:831737646743:web:24debf76e0470679',
    measurementId: 'G-M6N1FSPCZ7'
};

export const prodConfig = {
    apiKey: 'AIzaSyBhBIFb2tF82x0YWa6TmkspA7khQS0Udw0',
    authDomain: 'phio-main.firebaseapp.com',
    databaseURL: 'https://phio-main.firebaseio.com',
    projectId: 'phio-main',
    storageBucket: 'phio-main.appspot.com',
    messagingSenderId: '269752456677',
    appId: '1:269752456677:web:9584326099de318a',
    measurementId: 'G-9KRV02FYP4'
};

export const app = initializeApp(isDevMode(window.location.href) ? stageConfig : prodConfig);
export const auth = getAuth(app);

export const firestoreInstance = (databaseId?: string) => {
    if (databaseId) {
        window.localStorage.setItem('fid', databaseId);
    }
    const prev = window.localStorage.getItem('fid') ?? '(default)';
    return getFirestore(app, databaseId ?? prev);
};
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
