import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Link from '@mui/material/Link';
import {
    Alert,
    AlertTitle,
    Backdrop,
    CircularProgress,
    Collapse,
    Divider,
    IconButton
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import containSpecialCharOrDigits from 'validation/containSpecialCharOrDigits';
import { differenceInDays, isValid, parse, set, startOfToday, sub } from 'date-fns';
import CSVFileValidator, { FieldSchema } from './csvParser';
import { TriageRegistrationConfig } from '@eql-ai/typescript-types';
import validateEmailAddress from 'validation/emailValidation';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import postcodeValidator from 'validation/postcodeValidation';
import downloadCsvTemplate from './downloadPatientsCsvTemplate';
import validateNhsNumber from 'validation/nhsNumber';
import { registerPatient } from 'services/patient';
import DOMPurify from 'dompurify';

interface BulkPatientsUploadProps {
    registrationConfig: TriageRegistrationConfig | null | undefined;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

export default function BulkPatientsUpload(props: BulkPatientsUploadProps) {
    const { registrationConfig } = props;

    const [fileName, setFileName] = React.useState('');
    const [invalidData, setInvalidData] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [uploadingFile, setUploadingFile] = React.useState(false);
    const [savingData, setSavingData] = React.useState(false);
    const [isFileValid, setIsFileValid] = React.useState(false);
    const [fileTooBigError, setFileTooBigError] = React.useState('');

    const navigate = useNavigate();
    const region = useSelector((state: RootState) => state.AuthReducer.currentRegion);
    const phoneRegion = region === 'aus' ? 'AU' : 'GB';

    const requiredError = (headerName: string, rowNumber: number) => {
        return `${headerName} is required on row ${rowNumber}`;
    };

    const validateFirstAndLastName = (firstName: string, headerName: string, rowNumber: number) => {
        if (firstName && firstName.trim().length < 2) {
            return `${headerName} is not valid: Needs to be min 2 characters on row ${rowNumber} `;
        }
        if (firstName && firstName.trim().length >= 2 && containSpecialCharOrDigits(firstName)) {
            return `${headerName} is not valid: Do not use special characters or digits on row ${rowNumber}`;
        }
        return '';
    };

    const validateDob = (dob: string, headerName: string, rowNumber: number) => {
        const minDate = set(
            sub(startOfToday(), { years: registrationConfig?.min_patient_age || 16 }),
            {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            }
        );

        const formattedDob = parse(dob, 'yyyy-MM-dd', new Date());
        const isValidDob = isValid(formattedDob);

        if (dob && isValidDob === false) {
            return `${headerName} is not valid: Enter valid dob in format YYYY-MM-DD on row ${rowNumber}`;
        }
        if (dob && differenceInDays(minDate, formattedDob) < 0) {
            return `${headerName} is not valid: Patient must be over ${
                registrationConfig?.min_patient_age || 16
            } years old on row ${rowNumber}`;
        }

        return '';
    };

    const validateEmail = (email: string, row: string[], headerName: string, rowNumber: number) => {
        const value = email?.trim();
        const phoneNumber = row[4];

        if (!value && !phoneNumber) {
            return `Either Email Address or Phone number is required on row ${rowNumber}`;
        }
        if (value && !validateEmailAddress(value)) {
            return `${headerName} is not valid: Enter a valid email address on row ${rowNumber}`;
        }
        return '';
    };

    const validatePhone = (
        phoneNumber: string,
        row: string[],
        headerName: string,
        rowNumber: number
    ) => {
        const email = row[3];

        if (!phoneNumber && !email) {
            return `Either Email Address or Phone number is required on row ${rowNumber}`;
        }
        if (phoneNumber && !isValidPhoneNumber(phoneNumber, phoneRegion)) {
            return `${headerName} is not valid: Enter a valid phone number on row ${rowNumber}`;
        }
        return '';
    };

    const validatePostcode = (postcode: string, headerName: string, rowNumber: number) => {
        if (postcode && !postcodeValidator.validate(postcode.replace(/ /g, ''), region)) {
            return `${headerName} is not valid: Enter a valid postcode on row ${rowNumber}`;
        }
        return '';
    };

    const validateNhs = (nhsNumber: string, headerName: string, rowNumber: number) => {
        if (nhsNumber && !validateNhsNumber(nhsNumber)) {
            return `${headerName} is not valid: Enter a valid NHS number on row ${rowNumber}`;
        }
        return '';
    };

    const CSVConfig = {
        headers: [
            {
                name: 'Patient First Name',
                inputName: 'first_name',
                required: true,
                requiredError,
                validate: validateFirstAndLastName
            },
            {
                name: 'Patient Last Name',
                inputName: 'last_name',
                required: true,
                requiredError,
                validate: validateFirstAndLastName
            },
            {
                name: 'Date of Birth',
                inputName: 'dob',
                required: true,
                requiredError,
                validate: validateDob
            },
            {
                name: 'Email Address',
                inputName: 'email',
                required: false,
                dependentValidate: validateEmail
            },
            {
                name: 'Mobile Phone Number',
                inputName: 'phone',
                required: false,
                dependentValidate: validatePhone
            },
            {
                name: 'Postcode',
                inputName: 'postcode',
                required: false,
                validate: validatePostcode
            },

            {
                name: 'Area',
                inputName: 'gp_area',
                required: false
            },
            {
                name: 'GP',
                inputName: 'gp',
                required: false
            },
            {
                name: 'GP Postcode',
                inputName: 'gp_postcode',
                required: false,
                validate: validatePostcode
            },
            {
                name: 'Hospital',
                inputName: 'hospital',
                required: false
            },
            {
                name: 'Policy Number',
                inputName: 'policy_number',
                required: false
            },
            {
                name: 'Policy Holder Name',
                inputName: 'policy_holder_name',
                required: false
            },
            {
                name: 'NHS No',
                inputName: 'nhs_number',
                required: false,
                validate: validateNhs
            }
        ] as FieldSchema[]
    };

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            event.preventDefault();

            setUploadingFile(true);
            setFileName('');
            setFileTooBigError('');

            if (!event?.target?.files) return;

            if (event.target.files.length === 0) return;

            setFileName(event?.target?.files[0].name);

            await CSVFileValidator(event.target.files[0], CSVConfig).then((csvData: any) => {
                if (csvData.data?.length > 500) {
                    setFileTooBigError('This file is too big. Please select file up to 500 rows.');
                    event.target.files = null;
                    return;
                }
                setInvalidData(csvData?.inValidData);
                setData(csvData.data);
            });

            setIsFileValid(true);
        } catch (e) {
            console.log(e);
        } finally {
            setUploadingFile(false);
        }
    };

    const handleSubmitData = async () => {
        try {
            setSavingData(true);

            const promises: any[] = [];
            data.forEach((patient) => {
                const sanitizedPatient = Object.keys(patient).reduce((acc: any, key: any) => {
                    return { ...acc, [key]: DOMPurify.sanitize(patient[key]) };
                }, {});

                const phoneNumber = sanitizedPatient?.phone
                    ? parsePhoneNumber(sanitizedPatient.phone, phoneRegion)
                    : null;

                promises.push(
                    registerPatient({
                        ...sanitizedPatient,
                        phone: phoneNumber ? phoneNumber?.formatInternational() : null,
                        client_id: registrationConfig?.client_id,
                        client_name: registrationConfig?.client_name,
                        referral_type: 'admin',
                        case_status: 'open',
                        ip_reference: registrationConfig?.ip_reference,
                        ip_name: registrationConfig?.provider_route
                    })
                );
            });
            await Promise.all(promises);

            navigate('/dashboard/access/patient');
        } catch (e) {
            console.log(e);
        } finally {
            setSavingData(false);
        }
    };

    const renderUploadScreen = () => (
        <>
            <FileUploadIcon sx={{ fontSize: 80 }} />
            <Button component="label" role={undefined} variant="contained" tabIndex={-1}>
                Upload file
                <VisuallyHiddenInput
                    type="file"
                    accept=".csv"
                    onChange={onFileChange}
                    onClick={(event: any) => {
                        event.target.files = null;
                    }}
                />
            </Button>
            <Typography variant="body2" gutterBottom>
                {fileName || 'File not selected'}
            </Typography>
            <Typography variant="h6" fontWeight="bold" mt={1}>
                Select a CSV file to import your patient list
            </Typography>
            <Typography variant="body2" gutterBottom mb={3}>
                Please select a CSV file up to 500 rows.
            </Typography>
            <Link
                component="button"
                variant="body1"
                fontWeight="bold"
                color="secondary"
                onClick={downloadCsvTemplate}
            >
                Need a template?
            </Link>
        </>
    );

    const renderSubmitDataScreen = () => (
        <>
            <CheckCircleOutlineIcon sx={{ fontSize: 100, color: 'green' }} />
            <Typography variant="h6" fontWeight="bold" mt={1} mb={2}>
                File uploaded successfully, {data?.length} patients found.
            </Typography>
            <Button
                component="label"
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={handleSubmitData}
            >
                Complete import
            </Button>
        </>
    );

    return (
        <>
            <Box
                width="100%"
                minHeight={350}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {!fileTooBigError && invalidData?.length === 0 && isFileValid
                    ? renderSubmitDataScreen()
                    : renderUploadScreen()}
            </Box>

            {(!!fileTooBigError || invalidData?.length !== 0) && (
                <Alert
                    severity="error"
                    action={
                        !fileTooBigError && (
                            <IconButton
                                aria-label="expand"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        )
                    }
                >
                    <AlertTitle sx={{ fontWeight: 'bold' }}>Error</AlertTitle>
                    {!!fileTooBigError
                        ? fileTooBigError
                        : `We found ${invalidData?.length} errors in your file. Please fix them and upload your file again.`}

                    {!fileTooBigError && (
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            {invalidData?.map((item: any) => (
                                <Box key={uuidv4()}>{item.message} </Box>
                            ))}
                        </Collapse>
                    )}
                </Alert>
            )}

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                }}
                open={uploadingFile || savingData}
            >
                <Box
                    width="100%"
                    minHeight={350}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress color="inherit" size={60} />
                    {uploadingFile && (
                        <Typography variant="h5" component="p" fontWeight="bold" mt={1} mb={2}>
                            Uploading...
                        </Typography>
                    )}
                    {savingData && (
                        <Typography variant="h5" component="p" fontWeight="bold" mt={1} mb={2}>
                            Saving data...
                        </Typography>
                    )}
                    <Typography variant="h6" fontWeight="bold" mt={1}>
                        This can take a moment...
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        Please do not refresh the page.
                    </Typography>
                </Box>
            </Backdrop>
        </>
    );
}
