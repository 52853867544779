import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import useConfirm from '../hooks/useConfirm';

export default function ConfirmDialog() {
    const { prompt = '', isOpen = false, proceed, cancel, title } = useConfirm();

    return isOpen ? (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title ? title : 'CONFIRM YOUR ACTION'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="span" id="alert-dialog-description">
                    {prompt}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={cancel}>
                    Cancel
                </Button>
                <Button onClick={proceed} autoFocus>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
