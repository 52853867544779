import {
    SessionEndReviewActionTypes,
    SessionReviewState,
    ExerciseBagState,
    RESET_REVIEW_BAG,
    RESET_REVIEW,
    ADD_TO_EXERCISE_REVIEW_BAG,
    UPDATE_EXERCISE_IN_REVIEW,
    REMOVE_FROM_EXERCISE_REVIEW_BAG,
    ADD_SESSION_TO_STATE,
    REMOVE_SESSION_FROM_SESSION_STATE
} from './types';

const initialState: SessionReviewState = {};

const reviewBagInitialState: ExerciseBagState = {
    exercises: {}
};

export function exerciseReviewBagReducer(
    state = reviewBagInitialState,
    action: SessionEndReviewActionTypes
): ExerciseBagState {
    switch (action.type) {
        case ADD_TO_EXERCISE_REVIEW_BAG: {
            return {
                exercises: {
                    ...state.exercises,
                    [action.payload.sessionId]: {
                        [action.payload.exercise.id]: {
                            ...action.payload.exercise
                        }
                    }
                }
            };
        }
        case UPDATE_EXERCISE_IN_REVIEW: {
            return {
                exercises: {
                    ...state.exercises,
                    [action.payload.sessionId]: {
                        [action.payload.updatedValues.id]: {
                            ...action.payload.updatedValues
                        }
                    }
                }
            };
        }
        case REMOVE_FROM_EXERCISE_REVIEW_BAG: {
            delete state.exercises[action.payload];
            return {
                exercises: {
                    ...state.exercises
                }
            };
        }
        case RESET_REVIEW_BAG: {
            return {
                exercises: {}
            };
        }
        default:
            return state;
    }
}

export function sessionEndFeedbackReducer(
    state = initialState,
    action: SessionEndReviewActionTypes
): SessionReviewState {
    switch (action.type) {
        case ADD_SESSION_TO_STATE: {
            return {
                ...state,
                [action.payload]: true
            };
        }
        case REMOVE_SESSION_FROM_SESSION_STATE: {
            delete state[action.payload];
            return {
                ...state
            };
        }
        case RESET_REVIEW: {
            return initialState;
        }
        default:
            return state;
    }
}
