import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Text = styled('p')(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
}));

const TruncatedText = (props: { text: string }) => {
    const { text } = props;

    return (
        <Box>
            <Text>{text}</Text>
        </Box>
    );
};

export default TruncatedText;
