import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

const DashboardIndexView = () => {
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                height: '60vh'
            }}
        >
            <Typography variant="h3" sx={{ fontWeight: 800 }}>
                Hello, {clinician.first_name ?? ''}.
            </Typography>
            <br />
            <Typography variant="h4">Welcome back to the Phio Clinical Portal.</Typography>
        </Box>
    );
};

export default DashboardIndexView;
