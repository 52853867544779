import React from 'react';
import { Typography, List, ListItem, ListItemText, ListItemIcon, Link, Box } from '@mui/material';
import { getCurrentMedicalDeviceLabel } from 'data/medicalDevice';
import medicalDeviceIcon from 'assets/images/medical_device.svg';
import versionIcon from 'assets/images/version.svg';
import manufacturerIcon from 'assets/images/manufacturer.svg';
import releaseIcon from 'assets/images/release.svg';

const AboutThisPortal = () => {
    const [medicalDeviceVersionStr, setMedicalDeviceVersionStr] = React.useState('');

    React.useEffect(() => {
        (async () => {
            const currentMdLabel = await getCurrentMedicalDeviceLabel();
            if (!currentMdLabel) {
                setMedicalDeviceVersionStr('Unknown');
                return;
            }
            setMedicalDeviceVersionStr(currentMdLabel);
        })();
    }, []);

    return (
        <>
            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">
                About Phio Access Portal
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '16px' }} mb={2}>
                The primary purpose of <b>Phio Access Plus Admin Portal</b> is intended to provide
                Secondary Users access to the information gathered from the Primary user about their
                MSK pain, injury or dysfunction and the referral care pathway outcome.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom fontWeight="bold">
                How to use the Portal
            </Typography>
            <Typography variant="body1" mb={3}>
                Use the interface provided to render PDF reports for any patients that have
                interacted with Phio Access Plus.{' '}
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom fontWeight="bold">
                When to use the Portal
            </Typography>
            <Typography variant="body1" mb={3}>
                Use this portal when a patient has interacted with Phio Access Plus so that you can
                access the conversation transcript and the referral care pathway outcome.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom fontWeight="bold">
                How the Portal works
            </Typography>
            <Typography variant="body1" mb={3}>
                The portal will display all data gathered from the primary user by Phio Access Plus
                and on request render this information into the PDF template to be downloaded.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom fontWeight="bold">
                Our Policies
            </Typography>
            <Typography variant="body1" mb={3}>
                Our policies tell you important information about using the portal.
            </Typography>
            <Box mb={3}>
                <ul>
                    <li>
                        <Link
                            href="https://phio.eql.ai/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy policy
                        </Link>
                    </li>
                </ul>
            </Box>
            <Typography variant="h6" component="h2" fontWeight="bold">
                Software Information
            </Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <img src={medicalDeviceIcon} alt="medical device" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <div>
                                App name
                                <br />
                                <b>Phio Access Plus</b>
                            </div>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <img src={versionIcon} alt="version" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography style={{ fontWeight: 'bold' }}>
                                {medicalDeviceVersionStr}
                            </Typography>
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                    <ListItemIcon>
                        <img src={manufacturerIcon} alt="manufacturer" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <div>
                                Manufacturer
                                <br />
                                <b>
                                    EQL
                                    <br />
                                    16 Eaton Ave
                                    <br />
                                    Buckshaw Village
                                    <br />
                                    Chorley
                                    <br />
                                    PR7 7NAQ
                                </b>
                            </div>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <img src={releaseIcon} alt="release" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <div>
                                Date of release
                                <br />
                                <b>14/05/2021</b>
                            </div>
                        }
                    />
                </ListItem>
            </List>
        </>
    );
};

export default AboutThisPortal;
