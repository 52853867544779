import { functions } from 'config/ApiService';
import { httpsCallable } from 'firebase/functions';

export type Region = { key: string; value: string; label: string };

type Response = {
    regions: Region[];
};

export function fetchRegions() {
    return httpsCallable<undefined, Response>(functions, 'intl-regions')();
}
