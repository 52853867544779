import { DBModel } from './dbModel';
import CustomerFlagType from '../domain/types/CustomerFlagType';
import { DocumentReference } from 'firebase/firestore';

type CustomerConfig = {
    clientName: string;
    flags: CustomerFlagType[];
    clinicalServicesEnabled?: boolean;
    region?: 'aus' | 'gb';
    isInternalCustomer?: boolean;
};

class CustomerModel extends DBModel {
    static readonly FLAGS = 'flags';

    static readonly CLIENT_NAME = 'name';

    static readonly CLINICAL_SERVICES_ENABLED = 'clinical_services_enabled';

    static readonly REGION = 'region';

    static readonly IS_INTERNAL_CUSTOMER = 'is_internal_customer';

    flags: CustomerFlagType[];

    clientName: string;

    clinicalServicesEnabled?: boolean;

    region?: 'gb' | 'aus';

    isInternalCustomer?: boolean;

    constructor(config: CustomerConfig, ref?: DocumentReference) {
        super(ref);
        this.flags = config.flags;
        this.clientName = config.clientName;
        this.clinicalServicesEnabled = config.clinicalServicesEnabled;
        this.region = config.region;
        this.isInternalCustomer = config.isInternalCustomer;
    }
}

export default CustomerModel;
