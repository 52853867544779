import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { addYears, isValid, isWithinInterval } from 'date-fns';

interface DateRangeSelectorProps {
    fullWidth?: boolean;
    label?: string;
    defaultDate?: Date;
    onDateChange?: (date: Date) => void;
    error?: {
        message?: string;
    };
    required?: boolean;
    inputFormat?: string;
}

const DateRangeSelector = (props: DateRangeSelectorProps) => {
    const { label, onDateChange, required, inputFormat, fullWidth, error, defaultDate } = props;

    const [value, setValue] = useState<Date | null>(defaultDate ? defaultDate : new Date());
    const [validDateError, setValidDateError] = useState<string>('');

    const handleChange = (newValue: Date | null) => {
        if (!newValue) {
            return;
        }

        const isValidDate =
            isValid(newValue) &&
            isWithinInterval(newValue, {
                start: new Date(1900, 0, 1),
                end: addYears(new Date(), 10)
            });

        if (!isValidDate) {
            setValidDateError('Invalid date');
            return;
        }
        setValidDateError('');
        setValue(newValue);
        if (onDateChange && newValue) {
            onDateChange(newValue);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label || 'Date Picker'}
                format={inputFormat || 'dd/MM/yyyy'}
                onChange={handleChange}
                value={value}
                slotProps={{
                    textField: {
                        helperText: error?.message || validDateError,
                        error: error || validDateError ? true : false,
                        required: required,
                        fullWidth: fullWidth
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DateRangeSelector;
