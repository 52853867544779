import React from 'react';
import {
    Box,
    Container,
    Grid,
    IconButton,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import SignOutIcon from '@mui/icons-material/ExitToAppOutlined';
import AuthActions from 'redux/actions/AuthActions';
import { httpsCallable } from 'firebase/functions';
import { RootState } from 'redux/reducers';
import { useSnackbar } from 'notistack';
import { auth, functions } from '../../config/ApiService';

const PermissionUpgrade = () => {
    const [loading, setLoading] = React.useState(false);
    const accessLevel = useSelector((state: RootState) => state.AuthReducer.accessLevel);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            dispatch({ type: AuthActions.SIGN_OUT });
        } catch (e: any) {
            console.log(e);
        }
    };

    const handlePermissionUpgrade = async () => {
        try {
            setLoading(true);
            await httpsCallable(
                functions,
                'permissions-migrate'
            )({
                current_role: accessLevel
            });
            enqueueSnackbar('Your request has been submitted.', { variant: 'success' });
            handleSignOut();
        } catch (e) {
            console.error(e);
            enqueueSnackbar('An error occurred while processing your request.', {
                variant: 'error'
            });
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <IconButton onClick={handleSignOut}>
                            <SignOutIcon /> Exit
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                <Typography variant="h3" component="h1" mb={3}>
                    Welcome back to Phio!
                </Typography>
                <Typography mb={2} maxWidth={450}>
                    We&apos;re excited to share that we&apos;ve enhanced our access management for
                    our Portals.
                </Typography>
                <Typography mb={2} maxWidth={500}>
                    To continue enjoying secure and seamless access, please update your account by
                    clicking the button below.
                </Typography>
                <Typography mb={3}>You will need to log back in after the update.</Typography>
                <Button onClick={handlePermissionUpgrade} variant="contained" disabled={loading}>
                    Update Account
                    {loading && <CircularProgress sx={{ marginLeft: 2 }} size={12} />}
                </Button>
                <Typography gutterBottom mt={5}>
                    If you need assistance or have any questions, our support team is here to help.
                </Typography>
            </Box>
        </Container>
    );
};

export default PermissionUpgrade;
