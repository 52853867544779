import { MouseEventHandler } from 'react';
import {
    Drawer as MuiDrawer,
    IconButton,
    List,
    Divider,
    Box,
    Theme,
    styled,
    Typography,
    ListItem,
    ListItemText
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useSelector } from 'react-redux';
import ListItemLink from './ListItemLink';
import { PackageRoutes } from 'routes/sideBarRoutes';
import phioLogo from '../assets/images/phio-main-logo.png';
import IconTooltip from './IconTooltip';

const drawerWidth = 240;

const openedMixin = (theme: Theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: any) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        })
    })
);

interface Props {
    open: boolean;
    onDrawerClose: MouseEventHandler;
}

const SideDrawer = (props: Props) => {
    const { open, onDrawerClose } = props;
    const permissions = useSelector<any, string[]>((state) => state.AuthReducer.permissions);
    // customer profile
    const customer = useSelector<any, any>((state) => state.AuthReducer.organisation);

    const renderAccessHeadersAndList = () => {
        const isAccessPlusAgent = customer?.phio_agent === 'phio_access_plus';
        return permissions.map((permission, index) => {
            const permissionPackage = PackageRoutes(isAccessPlusAgent)[permission];
            if (!permissionPackage) {
                return null;
            }
            return (
                <Box key={`${permission}_${index}`}>
                    {open && (
                        <List>
                            <ListItem disablePadding>
                                <ListItemText>
                                    <Typography
                                        variant="h6"
                                        component="p"
                                        p={2}
                                        pb={0}
                                        fontWeight="bold"
                                    >
                                        {permissionPackage.name}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    )}
                    <List>
                        {permissionPackage.routes.map((route: any, index: number) => (
                            <ListItemLink
                                to={route.path}
                                primary={route.routeName}
                                key={`${route.routeName}_${index}`}
                                icon={
                                    <IconTooltip title={route.routeName}>
                                        <route.icon />
                                    </IconTooltip>
                                }
                            />
                        ))}
                    </List>
                    <Divider />
                </Box>
            );
        });
    };

    return (
        <Drawer variant="permanent" anchor="left" open={open}>
            <DrawerHeader>
                <Box sx={{ display: 'flex', width: 180, height: 'auto', paddingLeft: '6px' }}>
                    <img
                        alt="phio"
                        src={phioLogo}
                        style={{ width: 100, display: 'flex', justifyContent: 'center' }}
                    />
                </Box>
                <IconButton onClick={onDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            {renderAccessHeadersAndList()}
        </Drawer>
    );
};

export default SideDrawer;
