import { combineReducers } from 'redux';
import { AppReducer } from 'redux/reducers/AppReducer';
import { AuthReducer } from 'redux/reducers/AuthReducer';
import {
    sessionEndFeedbackReducer,
    exerciseReviewBagReducer
} from '../store/sessionEndReview/reducers';

export const appReducer = combineReducers({
    AppReducer,
    AuthReducer,
    sessionEndFeedbackReducer,
    exerciseReviewBagReducer
});

export type RootState = ReturnType<typeof appReducer>;
