import React from 'react';
import {
    Button,
    Grid,
    Typography,
    TextField,
    Link,
    Paper,
    Box,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Theme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, functions } from '../../../config/ApiService';
import { makeStyles } from '@mui/styles';
import Copyright from 'components/CopyRight';
import BackgroundImg from '../../../assets/images/engage-portal-bkg.png';
import phioLogo from '../../../assets/images/phio-main-logo.png';
import { httpsCallable } from 'firebase/functions';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        backgroundImage: `url(${BackgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'left'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    phioLogo: {
        display: 'flex',
        width: 265,
        height: 97,
        [theme.breakpoints.down('sm')]: {
            width: 133,
            height: 49
        },
        [theme.breakpoints.up('md')]: {
            width: 133,
            height: 49
        },
        [theme.breakpoints.up('lg')]: {
            width: 265,
            height: 97
        }
    }
}));

const SignInContainer = () => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
        email: '',
        password: '',
        policyAccepted: false
    });

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const login = async () => {
        const weakPasswordChecker = httpsCallable<string, { isWeak: boolean }>(
            functions,
            'auth-weakPasswordChecker'
        );

        try {
            setLoading(true);

            // Weak password checker
            const res = await weakPasswordChecker(state.password);
            dispatch(AuthActionCreators.setHasWeakPassword(res.data.isWeak));

            // Perform login
            await signInWithEmailAndPassword(auth, state.email, state.password);
        } catch (e: any) {
            enqueueSnackbar(e.message, { variant: 'error' });
            setLoading(false);
        }
    };

    const disableButton = () => {
        const { email, password, policyAccepted } = state;
        return email.trim() === '' || password.trim() === '' || !policyAccepted;
    };

    return (
        <Grid container spacing={0} component="main" sx={{ height: '100vh' }}>
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
                <div className={classes.paper}>
                    <div className={classes.phioLogo}>
                        <img
                            alt="phio"
                            src={phioLogo}
                            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                        />
                    </div>
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={(theme) => ({ marginTop: theme.spacing(2) })}
                    >
                        Sign in
                    </Typography>
                    {/* <FormHelperText>Location Permission is required</FormHelperText> */}
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            inputProps={{
                                'aria-label': 'email address'
                            }}
                            onChange={(evt) => setState({ ...state, email: evt.target.value })}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            inputProps={{
                                'aria-label': 'password'
                            }}
                            onChange={(evt) => setState({ ...state, password: evt.target.value })}
                        />
                        <FormHelperText>
                            Phio by EQL is for the use of authorized users only. By logging into
                            Phio by EQL, you agree to abide by the terms of use and only use Phio
                            for its intended purpose. All Individuals using Phio by EQL with or
                            without authority, or in excess of their authority, are subject to
                            having all of their activities in Phio and downloading from Phio by EQL
                            monitored and recorded by EQL. Anyone using Phio by EQL expressly
                            consents to such monitoring and is advised that if such monitoring
                            reveals possible evidence of criminal activity, system personnel may
                            provide the evidence of such monitoring to law enforcement officials.
                        </FormHelperText>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={state.policyAccepted}
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'Usage Policy'
                                    }}
                                    onChange={(evt) =>
                                        setState({ ...state, policyAccepted: evt.target.checked })
                                    }
                                />
                            }
                            label="By logging in, I accept the Acceptable Usage Policy above."
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={login}
                            disabled={disableButton()}
                            sx={(theme) => ({ margin: theme.spacing(3, 0, 2) })}
                        >
                            Sign In
                            {loading && (
                                <Box ml={2} display="flex" justifyContent="center">
                                    <CircularProgress size={20} color="secondary" />
                                </Box>
                            )}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgotpassword" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/* <Grid item>
                                <Link href="/signup" variant="body2">
                                    Don&apos;t have an account? Sign Up
                                </Link>
                            </Grid> */}
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignInContainer;
