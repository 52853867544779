import DomainState from 'core/constants/DomainState';

export interface ExerciseReview {
    currentDifficultyLevel: number;
    clinicianChange: number;
    reviewNotes: string;
    reviewMessage: string;
    id: string;
    reviewed: boolean;
    keepBlocked: DomainState;
}

export interface SessionReviewState {
    [propName: string]: any;
}

export interface ExerciseBag {
    [propName: string]: {
        [propName: string]: ExerciseReview;
    };
}
export interface ExerciseBagState {
    exercises: ExerciseBag;
}

// export const UPDATE_REVIEW = 'UPDATE_REVIEW';
// export const SET_SESSION_EXERCISES_REVIEWED = 'SET_SESSION_EXERCISES_REVIEWED';
// export const SET_SESSION_FEEDBACK_ID = 'SET_SESSION_FEEDBACK_ID';
// export const SET_REVIEW_BAG_STATUS = 'SET_REVIEW_BAG_STATUS';

export const RESET_REVIEW = 'RESET_REVIEW';
export const RESET_REVIEW_BAG = 'RESET_REVIEW_BAG';
export const ADD_TO_EXERCISE_REVIEW_BAG = 'ADD_TO_EXERCISE_REVIEW_BAG';
export const REMOVE_FROM_EXERCISE_REVIEW_BAG = 'REMOVE_FROM_EXERCISE_REVIEW_BAG';
export const REMOVE_SESSION_FROM_SESSION_STATE = 'REMOVE_SESSION_FROM_SESSION_STATE';
export const ADD_SESSION_TO_STATE = 'ADD_SESSION_TO_STATE';
export const UPDATE_EXERCISE_IN_REVIEW = 'UPDATE_EXERCISE_IN_REVIEW';

interface AddToExerciseReviewBag {
    type: typeof ADD_TO_EXERCISE_REVIEW_BAG;
    payload: {
        sessionId: string;
        exercise: ExerciseReview;
    };
}

interface RemoveFromExerciseReviewBag {
    type: typeof REMOVE_FROM_EXERCISE_REVIEW_BAG;
    payload: string;
}

interface RemoveSessionFromState {
    type: typeof REMOVE_SESSION_FROM_SESSION_STATE;
    payload: string;
}

interface AddSessionToState {
    type: typeof ADD_SESSION_TO_STATE;
    payload: string;
}

interface UpdateExerciseInReview {
    type: typeof UPDATE_EXERCISE_IN_REVIEW;
    payload: {
        sessionId: string;
        updatedValues: ExerciseReview;
    };
}

// interface UpdateReview {
//     type: typeof UPDATE_REVIEW;
//     payload: ExerciseBag;
// }

interface ResetReview {
    type: typeof RESET_REVIEW;
}

interface ResetReviewBag {
    type: typeof RESET_REVIEW_BAG;
}

// interface SetSessionExercisesReviewed {
//     type: typeof SET_SESSION_EXERCISES_REVIEWED;
//     payload: boolean;
// }

// interface SetSessionFeedbackId {
//     type: typeof SET_SESSION_FEEDBACK_ID;
//     payload: string;
// }

export type SessionEndReviewActionTypes =
    | ResetReview
    | ResetReviewBag
    | UpdateExerciseInReview
    | AddSessionToState
    | RemoveSessionFromState
    | RemoveFromExerciseReviewBag
    | AddToExerciseReviewBag;
