import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Backdrop, Box, CircularProgress, Container, Paper, Typography } from '@mui/material';
import AddPatientForm from './PatientForm';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { TriageRegistrationConfig } from '@eql-ai/typescript-types';
import { getRegistrationConfigByClientId } from 'data/registrationConfig';

const AddPatientContainer = () => {
    const [loading, setLoading] = React.useState(false);
    const [registrationConfig, setRegistrationConfig] = React.useState<
        TriageRegistrationConfig | null | undefined
    >();

    const params = useParams();
    const navigate = useNavigate();

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    const fetchRegConfig = async () => {
        try {
            setLoading(true);

            const data = await getRegistrationConfigByClientId(client.id);

            setRegistrationConfig(data as unknown as TriageRegistrationConfig);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            await fetchRegConfig();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <>
            <Container>
                <Box>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            fontWeight: 'bold',
                            marginTop: '16px'
                        }}
                    >
                        Create patient for {client.name}
                    </Typography>
                </Box>

                <Paper sx={{ minHeight: '400px' }}>
                    <AddPatientForm
                        onDismiss={() => navigate(-1)}
                        registrationConfig={registrationConfig}
                    />
                </Paper>
            </Container>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default AddPatientContainer;
