import { IMapper } from 'core/interfaces/mapper';
import CustomerModel from 'core/models/CustomerModel';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';

class CustomerModelMapper implements IMapper<CustomerModel> {
    fromSnapshot(snapshot: DocumentSnapshot): CustomerModel | undefined {
        const data = snapshot && snapshot.data();
        if (!data) {
            return undefined;
        }

        return new CustomerModel(
            {
                clientName: data[CustomerModel.CLIENT_NAME],
                flags: data[CustomerModel.FLAGS],
                clinicalServicesEnabled: data[CustomerModel.CLINICAL_SERVICES_ENABLED] || false,
                region: data[CustomerModel.REGION] || 'gb',
                isInternalCustomer: data[CustomerModel.IS_INTERNAL_CUSTOMER] || false
            },
            snapshot.ref
        );
    }

    toMap(data: CustomerModel): DocumentData {
        return {
            [CustomerModel.CLIENT_NAME]: data.clientName,
            [CustomerModel.FLAGS]: data.flags,
            [CustomerModel.CLINICAL_SERVICES_ENABLED]: data.clinicalServicesEnabled || false,
            [CustomerModel.REGION]: data.region || 'gb',
            [CustomerModel.IS_INTERNAL_CUSTOMER]: data.isInternalCustomer || false
        };
    }
}

export default CustomerModelMapper;
