import {
    CollectionReference,
    DocumentData,
    getDocs,
    limit,
    query,
    Query,
    QueryConstraint,
    QueryDocumentSnapshot,
    startAfter,
    where,
    orderBy
} from 'firebase/firestore';

export type QueryFilter = {
    field: string;
    operator:
        | '<'
        | '<='
        | '=='
        | '>'
        | '>='
        | '!='
        | 'array-contains'
        | 'array-contains-any'
        | 'in'
        | 'not-in';
    value: any;
};

type FilterOrder = {
    field: string;
    direction: 'asc' | 'desc';
};

type QueryParams = {
    filters: QueryFilter[];
    queryOrderBy?: FilterOrder;
    queryLimit?: number;
    cursor?: QueryDocumentSnapshot<DocumentData>;
};

/**
 * Represenst Cursor Query
 */
class CursorQuery {
    constructor(private ref: CollectionReference | Query) {}

    /**
     * Builds the query.
     * @param param
     * @returns
     */
    private _build(param: QueryParams): Query {
        const { filters, cursor, queryLimit, queryOrderBy } = param;
        let _query: Query | undefined = undefined;
        const _queryConstraints: QueryConstraint[] = [];
        for (let i = 0; i < filters.length; i += 1) {
            const val = filters[i];
            _queryConstraints.push(where(val.field, val.operator, val.value));
        }
        if (queryOrderBy) {
            _queryConstraints.push(orderBy(queryOrderBy.field, queryOrderBy.direction));
        }
        if (cursor) {
            _queryConstraints.push(startAfter(cursor));
        }
        _query = query(this.ref, ..._queryConstraints, limit(queryLimit || 100));
        return _query;
    }

    /**
     * Returns documents that match the specified query and the cursor to fetch the next page.
     * @param param
     * @returns {object}
     */
    public async getDocumentsWithPagination(param: QueryParams): Promise<{
        data: QueryDocumentSnapshot<DocumentData>[] | undefined;
        cursor?: QueryDocumentSnapshot;
    }> {
        const _query = this._build(param);
        const _querySnapshot = await getDocs(_query);
        if (_querySnapshot.empty) {
            return { data: undefined, cursor: undefined };
        }
        return {
            data: _querySnapshot.docs,
            cursor: _querySnapshot.docs[_querySnapshot.docs.length - 1]
        };
    }

    /**
     * Gets all documents that match the specified query.
     * @param {QueryParams} param
     * @returns {Array<object>}
     */
    public async getAllDocuments(
        param: QueryParams
    ): Promise<QueryDocumentSnapshot<DocumentData>[] | undefined> {
        let next = undefined;
        const temp: QueryDocumentSnapshot<DocumentData>[] = [];
        do {
            const _query = this._build({ ...param, cursor: next });
            const _querySnapshot = await getDocs(_query);
            const _data = _querySnapshot.docs;
            next = _querySnapshot.docs[_querySnapshot.docs.length - 1];
            _data.forEach((doc) => {
                temp.push(doc);
            });
        } while (next !== undefined);
        return temp;
    }
}

export default CursorQuery;
